<template>
  <el-dialog :title="title" :visible.sync="openDialog" width="900px" :show-close="true"
    class="dialog-vertical" @close="cancel">
    <div class="member-list">
      <el-table :data="reportList" class="thead-light" stripe style="width: 100%" :height="300"
        v-loading="loading" @sort-change="sortChange">
        <!-- 序号 -->
        <el-table-column v-if="type==='black'" width="100" type="index" :index="indexMethod" label="序号"
          :key="Math.random()"></el-table-column>
        <template v-for="(th, index) in thead[type]">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
            <template slot-scope="scope">
              <span>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <div class="pagination" v-if="pageData.last_page >1">
        <el-pagination background layout="prev, pager, next" :page-size="pageData.per_page"
          :total="pageData.total" :current-page="Number(pageData.current_page)" @current-change="pageChange">
        </el-pagination>
        <el-input class="number-input" type="number" min="1" :max="pageData.last_page" auto-complete="off"
          v-model="requesData.page"></el-input>
        <el-button class="page-jump" type="primary" @click="changePage">GO</el-button>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  ReportList,
  logRecodeList,
  UserBlackRecode,
} from "../../api/content-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    id: {
      type: [Number, String],
    },
  },
  data() {
    return {
      loading: false, //加载
      title: "被举报记录",
      thead: {
        report: [
          { label: "ID", prop: "id", minWidth: 60 },
          { label: "会员名称", prop: "nickname", minWidth: 100 },
          { label: "架构/会内职务", prop: "title_name", minWidth: 150 },
          { label: "说明", prop: "description", minWidth: 150 },
          {
            label: "举报时间",
            prop: "create_time",
            minWidth: 150,
            sortable: true,
          },
        ],
        handle: [
          { label: "ID", prop: "id", minWidth: 60 },
          { label: "操作人", prop: "nickname", minWidth: 100 },
          { label: "操作说明", prop: "content", minWidth: 250 },
          {
            label: "操作时间",
            prop: "create_time",
            minWidth: 150,
            sortable: true,
          },
        ],
        black: [
          { label: "拉黑原因", prop: "why", minWidth: 150 },
          { label: "拉黑时间", prop: "create_time", minWidth: 150 },
          { label: "移出时间", prop: "update_time", minWidth: 150 },
        ],
      },
      reportList: [], // 互动列表
      //数据请求对象
      requesData: {
        id: 0,
        page_size: 15, //每页多少条数据
        page: 1,
        is_desc: -1,
        order_by: "create_time",
      },
      pageData: {
        total: 15,
        per_page: 15,
        current_page: 1,
        last_page: 1,
      },
      AllSourceList: [],
      selectArr: [], //勾选的数组id
    };
  },
  methods: {
    // 获取记录列表
    getRecodeList() {
      this.loading = true;
      if (this.type === "report") {
        ReportList(this.requesData).then((res) => {
          const { data } = res;
          this.reportList = data.data;
          this.pageData = data;
          this.loading = false;
        });
      } else if (this.type === "handle") {
        logRecodeList(this.requesData).then((res) => {
          const { data } = res;
          this.reportList = data.data;
          this.pageData = data;
          this.loading = false;
        });
      } else {
        UserBlackRecode(this.requesData).then((res) => {
          const { data } = res;
          this.reportList = data.data;
          this.pageData = data;
          this.loading = false;
        });
      }
    },
    // 序号计算
    indexMethod(index) {
      return index + 1;
    },
    //表格排序
    sortChange(column) {
      this.requesData.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.requesData.order_by = column.prop;
      this.getRecodeList();
    },
    // 点击改变页数
    pageChange(e) {
      this.requesData.page = e;
      this.getRecodeList();
    },
    // 输入改变页数
    changePage() {
      if (
        Number(this.requesData.page) > 0 &&
        Number(this.requesData.page) <= Number(this.pageData.last_page)
      ) {
        this.getRecodeList();
      } else {
        this.requesData.page = this.pageData.current_page;
      }
    },
    // 取消
    cancel() {
      this.openDialog = false;
    },
  },
  watch: {
    id(val) {
      this.requesData.page = 1;
      this.requesData.id = val;
      this.getRecodeList();
    },
    type(val) {
      this.title =
        val === "report"
          ? "被举报记录"
          : val === "handle"
          ? "操作记录"
          : "拉黑/移出记录";
      this.requesData.page = 1;
      this.requesData.id = this.id;
      this.getRecodeList();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  width: 60%;
  display: flex;
  position: absolute;
  top: 20px;
  right: 20px;

  .el-select,
  .el-cascader,
  .el-input {
    flex: 1;
    margin-right: 10px;
  }
}

.pagination {
  display: flex;
  height: 30;
  margin-top: 20px;
  align-items: center;
  .number-input {
    max-width: 60px;
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      appearance: none;
    }
    ::v-deep.el-input__inner {
      height: 28px;
      border: 1px solid #dcdfe6;
      padding: 0 10px;
    }
  }
  .page-jump {
    height: 28px;
    line-height: 0;
    padding: 10px;
    margin-left: 10px;
  }
}
</style>
