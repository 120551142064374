<template>
  <el-dialog title="修改数据" :visible.sync="openDialog" width="600px" :show-close="true" class="dialog-vertical"
    @close="cancel">
    <el-table :data="tbody" class="thead-light" stripe style="width: 100%">
      <template v-for="(th, index) in thead">
        <el-table-column :prop="th.prop" :label="th.label" :min-width="th.minWidth || ''" :key="index">
          <template slot-scope="scope">
            <!-- 运营修改 -->
            <PopoverInputEdit v-if="th.prop === 'virtual_num'" input-type="number" :validator="validator"
              v-model="scope.row[th.prop]" />
            <!-- 普通 -->
            <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </template>
      <!-- 显示数据 -->
      <el-table-column label="显示数据">
        <template slot-scope="scope">
          {{scope.row.num + scope.row.virtual_num}}
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import { UpdateVirtual } from "../../api/content-list";
export default {
  components: { PopoverInputEdit },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String, Array],
    },
    rowData: {
      type: Object,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      tbody: [
        {
          name: "浏览量",
          num: 0,
          virtual_num: 0,
        },
        {
          name: "评论量",
          num: 0,
          virtual_num: 0,
        },
      ],
      thead: [
        { label: "", prop: "name", minWidth: 100 },
        { label: "用户数据", prop: "num", minWidth: 100 },
        { label: "运营修改", prop: "virtual_num", minWidth: 100 },
      ],
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      test: 0,
      saveLoading: false,
    };
  },
  methods: {
    // 保存
    submit() {
      const data = {
        id: this.id,
        virtual_view_num: this.tbody[0].virtual_num,
        virtual_comment_num: this.tbody[1].virtual_num,
      };
      this.saveLoading = true;
      UpdateVirtual(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.saveLoading = false;
          this.openDialog = false;
          this.updateList();
        })
        .catch((err) => {
          this.saveLoading = false;
        });
    },
    // 取消
    cancel() {
      this.openDialog = false;
    },
  },
  watch: {
    rowData: {
      deep: true,
      handler(val) {
        this.tbody[0].num = val.view_num;
        this.tbody[0].virtual_num = val.virtual_view_num;
        this.tbody[1].num = val.comments_count;
        this.tbody[1].virtual_num = val.virtual_comment_num;
        console.log("val", val, this.tbody);
      },
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.el-input {
  ::v-deep.el-input-group__prepend {
    background-color: #fff;
  }
}
</style>
