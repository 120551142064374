<template>
  <div class="list-page">
    <el-button v-if="!['deleted','yesblack','noblack'].includes(this.filterForm.tab)" class="top-btn"
      type="primary" size="small" @click="AddCircle">发布</el-button>
    <ListFilter v-model="filterForm" :uploadFilter="ok" :ids="selectArr" :page="pageData.current_page" />
    <list-tabs v-model="filterForm.tab" :isLoading="loading" :tabs="tabArr" @tab-click="handleClick">
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn">
        <el-button size="small" v-if="!['deleted','yesblack','noblack'].includes(filterForm.tab)"
          type="success" @click="handleChecked">通过</el-button>
        <el-button size="small" type="warning"
          v-if="!['deleted','yesblack','noblack'].includes(filterForm.tab)" @click="handleNoCheck">不通过
        </el-button>
        <el-button size="small" v-if="filterForm.tab === 'deleted'" type="primary" @click="handleReduct">还原
        </el-button>
        <el-button size="small" v-if="!['yesblack','noblack'].includes(filterForm.tab)" type="danger"
          @click="handleDel">删除</el-button>
        <el-button size="small" v-if="!['deleted','yesblack','noblack'].includes(filterForm.tab)"
          @click="handleBlock">拉黑</el-button>
        <el-button size="small" v-if="filterForm.tab === 'yesblack'" @click="handleTransform">移出黑名单
        </el-button>
      </div>
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%"
        @selection-change="handleSelectionChange" @sort-change="sortChange">
        <!-- 勾选 -->
        <el-table-column v-if="filterForm.tab !== 'noblack'" :key="Math.random()" class="select-check" fixed
          type="selection" width="55">
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" :width="140" fixed="right">
          <template slot-scope="scope">
            <div v-if="filterForm.tab !== 'yesblack' && filterForm.tab !== 'noblack'">
              <el-button type="text" size="small" v-if="filterForm.tab!=='deleted'"
                @click="DetailRow(scope.row)">查看</el-button>
              <el-button type="text" v-if="filterForm.tab==='deleted'" size="small"
                @click="ReductRow(scope.row)">
                还原
              </el-button>
              <el-divider direction="vertical" />
              <el-dropdown @command="handleClickRow($event, scope.row)">
                <span style="font-size:12px;color: #3576ff" class="el-dropdown-link">更多</span>
                <el-dropdown-menu v-if="filterForm.tab!=='deleted'" slot="dropdown">
                  <el-dropdown-item v-if="scope.row.title_name && scope.row.check_status !== 2"
                    command="CheckedRow">
                    通过</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.title_name && scope.row.check_status !== 1"
                    command="NoCheckRow">
                    不通过</el-dropdown-item>
                  <el-dropdown-item v-if="!scope.row.is_top && scope.row.check_status === 2"
                    command="setTopRow">置顶</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.is_top && scope.row.check_status === 2"
                    command="cancelTopRow">取消置顶</el-dropdown-item>
                  <el-dropdown-item v-if="!scope.row.is_pay" command="payRow">设为付费内容</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.is_pay" command="cancelPayRow">取消设为付费内容
                  </el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.title_name" command="BlockRow">拉黑
                  </el-dropdown-item>
                  <el-dropdown-item v-for="(item, index) in moreHandleList.normal" :key="index"
                    :command="item.command">{{ item.name }}</el-dropdown-item>
                </el-dropdown-menu>
                <el-dropdown-menu v-if="filterForm.tab==='deleted'" slot="dropdown">
                  <el-dropdown-item v-for="(item, index) in moreHandleList.deleted" :key="index"
                    :command="item.command">{{ item.name }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div v-else>
              <el-button v-if="filterForm.tab === 'yesblack'" type="text" size="small"
                @click="TransformRow(scope.row)">移出黑名单</el-button>
              <el-button v-else type="text" size="small" @click="RecodeRow(scope.row)">
                拉黑/移出记录
              </el-button>
            </div>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
            <template slot-scope="scope">
              <!-- 审核状态 -->
              <span v-if="th.prop === 'check_text'">
                <span :class="[
                      'status-dot',
                      scope.row.check_status === 2 ? 'green' : scope.row.check_status === 1?'grey':'yellow',
                    ]"></span>
                {{ scope.row[th.prop] }}
              </span>
              <!-- 付费状态 -->
              <span
                v-else-if="th.prop === 'is_pay'">{{ !scope.row.is_pay?'免费': scope.row.pay_type?scope.row[th.prop]+'积分':scope.row[th.prop]+'元' }}</span>
              <!-- 置顶 -->
              <span v-else-if="th.prop === 'top_sort'">
                <PopoverInputEdit v-if="scope.row[th.prop]" v-model="scope.row[th.prop]" input-type="number"
                  :validator="validator" @confirm="changeStatus(scope.row)" />
                <span v-else>--</span>
              </span>
              <!-- 举报数 -->
              <div v-else-if="th.prop === 'complaints_count'">
                {{ scope.row[th.prop] }}
                <el-button v-if="scope.row[th.prop] > 0" type="text" @click="checkReportRecode(scope.row)">查看
                </el-button>
              </div>
              <span
                v-else-if="th.prop === 'top_expire_time'">{{ scope.row.is_top?scope.row[th.prop]: '--' }}</span>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    <HandleDialog v-model="openHandleDialog" :id="ids" :userId="userIds" :type="DialogType"
      :updateList="reflashList" />
    <ChangeDataDialog v-model="openChangeDialog" :id="ids" :rowData="currentRow" :updateList="reflashList" />
    <RecodeDialog v-model="openRecodeList" :id="circle_id" :type="listType" />
    <!-- 举报记录弹窗 -->
    <ReportRecodeDialog v-model="showReportRecodeDialog" :requestData="ReportRecodeRequest"/>
  </div>
</template>

<script>
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import ListFilter from "../../components/CircleContent/ListFilter";
import Pagination from "@/base/components/Default/Pagination";
import {
  CircleList,
  Delete,
  putBack,
  RemoveBlack,
  Checked,
  updateSort,
  contentUpdateTop,
  PayConfig,
} from "../../api/content-list";
import HandleDialog from "../../components/CircleContent/HandleDialog";
import ChangeDataDialog from "../../components/CircleContent/ChangeDataDialog.vue";
import RecodeDialog from "../../components/CircleContent/RecodeDialog.vue";
import ReportRecodeDialog from "@/modules/common/components/ReportRecodeDialog";

export default {
  data() {
    return {
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "待审核", name: "checking", badge: 1 },
        { label: "付费内容", name: "isPay" },
        { label: "置顶", name: "isTop" },
        { label: "被举报", name: "report" },
        { label: "回收站", name: "deleted" },
        { label: "黑名单", name: "yesblack" },
        { label: "移出黑名单", name: "noblack" },
      ],
      loading: false, //加载
      thead: [
        { label: "ID", prop: "id", minWidth: 55 },
        { label: "内容", prop: "content_text", minWidth: 200 },
        { label: "内容类型", prop: "content_type_text", minWidth: 100 },
        { label: "内容分类", prop: "category_name", minWidth: 100 },
        { label: "发布人", prop: "nickname", minWidth: 110 },
        {
          label: "发布时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
        { label: "举报数", prop: "complaints_count", min_width: 100 },
        { label: "付费状态", prop: "is_pay", minWidth: 100 },
        { label: "审核状态", prop: "check_text", minWidth: 100 },
        { label: "置顶排序", prop: "top_sort", minWidth: 100 },
        {
          label: "置顶时间至",
          prop: "top_expire_time",
          minWidth: 160,
          sortable: true,
        },
      ],
      theadConfig: {
        normal: [
          { label: "ID", prop: "id", minWidth: 55 },
          { label: "内容", prop: "content_text", minWidth: 200 },
          { label: "内容类型", prop: "content_type_text", minWidth: 100 },
          { label: "内容分类", prop: "category_name", minWidth: 100 },
          { label: "发布人", prop: "nickname", minWidth: 110 },
          {
            label: "发布时间",
            prop: "create_time",
            minWidth: 160,
            sortable: true,
          },
          { label: "举报数", prop: "complaints_count", min_width: 100 },
          { label: "付费状态", prop: "is_pay", minWidth: 100 },
          { label: "审核状态", prop: "check_text", minWidth: 100 },
          { label: "置顶排序", prop: "top_sort", minWidth: 100 },
          {
            label: "置顶时间至",
            prop: "top_expire_time",
            minWidth: 160,
            sortable: true,
          },
        ],
        yesblack: [
          { label: "会员ID", prop: "user_id" },
          { label: "会员名称", prop: "nickname", minWidth: 110 },
          { label: "架构/会内职务", prop: "title_name", minWidth: 180 },
          { label: "拉黑原因", prop: "why", minWidth: 150 },
          {
            label: "拉黑时间",
            prop: "create_time",
            minWidth: 160,
            sortable: true,
          },
        ],
        noblack: [
          { label: "会员ID", prop: "user_id" },
          { label: "会员名称", prop: "nickname", minWidth: 110 },
          { label: "架构/会内职务", prop: "title_name", minWidth: 180 },
          { label: "上次拉黑原因", prop: "why", minWidth: 150 },
          {
            label: "上次拉黑时间",
            prop: "create_time",
            minWidth: 160,
            sortable: true,
          },
          {
            label: "移出时间",
            prop: "update_time",
            minWidth: 160,
            sortable: true,
          },
        ],
      },
      //筛选对象
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        is_demand: -1, //内容类型
        check: -1, // 审核状态
        category: -1, // 内容分类
        keyword: "", //内容关键字
        user_keyword: "", //用户名关键字
        tab: "checking", //当前筛选tab
        page_size: 15, //每页数据数
      },
      //筛选缓存
      filters: {
        normal: null,
        isPay: null,
        isTop: null,
        report: null,
        deleted: null,
        yesblack: null,
        noblack: null,
      },
      // 更多展开项
      moreHandleList: {
        normal: [
          { name: "修改数据", command: "EditRow" },
          { name: "操作记录", command: "handleRow" },
          { name: "删除", command: "deleteRow" },
        ],
        deleted: [
          { name: "详情", command: "DetailRow" },
          { name: "操作记录", command: "handleRow" },
          { name: "删除", command: "deleteRow" },
        ],
      },
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      user_id: 0,
      circle_id: 0,
      currentRow: {}, // 当前操作数据项
      pageData: {},
      openChatDialog: false,
      openHandleDialog: false,
      openChangeDialog: false,
      openRecodeList: false,
      listType: "report", // 弹窗列表类型
      DialogType: "", // 弹窗类型
      ids: [],
      userIds: [],
      tbody: [],
      selectArr: [], //勾选的数组id
      validatorArr: [], //验证条件
      showReportRecodeDialog: false, //是否显示举报记录弹窗
      ReportRecodeRequest: {
        page: 1,
        page_size: 15,
        data_type: "cyc_circle", // 举报数据类型
        data_table: "cyc_circle_content", // 举报数据表
        data_id: "", // 举报内容id
      },

    };
  },
  methods: {
    // 查看举报记录
    checkReportRecode({ id }) {
      this.ReportRecodeRequest.data_id = id;
      this.showReportRecodeDialog = true;
    },
    // 获取列表数据
    getCircleList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      CircleList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 发布资源
    AddCircle() {
      this.$router.push({
        name: "circleDetail",
        params: { id: 0, type: "add" },
      });
    },
    // 判断是否可勾选
    // selectable(row, index) {
    //   if (row.nickname !== "后台创建") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
        this.userIds = [];
        this.validatorArr = [];
      } else {
        this.selectArr = [];
        this.userIds = [];
        this.validatorArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.validatorArr.push(item.nickname);
          this.selectArr.push(item.id);
          this.userIds.push(item.user_id);
        });
      }
    },
    //点击更多操作
    handleClickRow(e, row) {
      this[e](row);
    },
    // 批量拉黑
    handleBlock() {
      if (this.selectArr.length) {
        if (this.validatorArr.findIndex((item) => item === "后台创建") != -1) {
          this.$message.error("后台创建数据不允许拉黑");
          return;
        }
        this.DialogType = "block";
        this.ids = this.selectArr;
        this.openHandleDialog = true;
      } else {
        this.$message.info("请选择数据项");
      }
    },
    //点击列表拉黑
    BlockRow(row) {
      this.DialogType = "block";
      this.ids = row.id;
      this.userIds = row.user_id;
      this.openHandleDialog = true;
    },
    // 批量通过审核
    handleChecked() {
      if (this.selectArr.length) {
        if (this.validatorArr.findIndex((item) => item === "后台创建") != -1) {
          this.$message.error("后台创建数据不允许通过审核");
          return;
        }
        this.$msgbox
          .confirm("确定要通过选中数据吗？", "提示", {
            type: "info",
          })
          .then((response) => {
            this.loading = true;
            Checked({ id: this.selectArr })
              .then((res) => {
                this.$message.success(res.msg);
                this.reflashList();
              })
              .catch((err) => {
                this.loading = false;
              });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表通过审核
    CheckedRow(row) {
      this.$msgbox
        .confirm("确定要通过此数据吗？", "提示", {
          type: "info",
        })
        .then((response) => {
          this.loading = false;
          Checked({ id: row.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.reflashList();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    // 批量不通过
    handleNoCheck() {
      if (this.selectArr.length) {
        if (this.validatorArr.findIndex((item) => item === "后台创建") != -1) {
          this.$message.error("后台创建数据不允许不通过审核");
          return;
        }
        this.DialogType = "nocheck";
        this.ids = this.selectArr;
        this.openHandleDialog = true;
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表不通过
    NoCheckRow(row) {
      this.DialogType = "nocheck";
      this.ids = row.id;
      this.openHandleDialog = true;
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (this.filterForm.tab === "deleted") {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.DialogType = "deleted";
          this.ids = this.selectArr;
          this.openHandleDialog = true;
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (this.filterForm.tab === "deleted") {
        this.$msgbox
          .confirm("确定要将此数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      } else {
        this.DialogType = "deleted";
        this.ids = row.id;
        this.openHandleDialog = true;
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      Delete(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm("确定要将此数据还原吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.Reduct({ id: row.id });
        })
        .catch((err) => {});
    },
    // 还原数据
    Reduct(id) {
      this.loading = true;
      putBack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 置顶操作
    setTopRow(row) {
      this.DialogType = "setTop";
      this.ids = row.id;
      this.openHandleDialog = true;
    },
    // 取消置顶
    cancelTopRow(row) {
      this.$msgbox
        .confirm("确定取消置顶此数据吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          const data = { id: row.id, top: 0 };
          this.loading = true;
          contentUpdateTop(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.reflashList();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((err) => {});
    },
    //设为付费
    payRow(row) {
      this.DialogType = "setPay";
      this.ids = row.id;
      this.openHandleDialog = true;
    },
    // 取消设为付费
    cancelPayRow(row) {
      this.$msgbox
        .confirm("确定该数据取消设为付费内容吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          const data = { id: row.id, pay: 0, pay_type: row.pay_type };
          this.loading = true;
          PayConfig(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.reflashList();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((err) => {});
    },
    // 修改数据
    EditRow(row) {
      this.ids = row.id;
      this.currentRow = row;
      this.openChangeDialog = true;
    },
    // 查看操作记录
    handleRow(row) {
      this.circle_id = row.id;
      this.listType = "handle";
      this.openRecodeList = true;
    },
    // 点击查看详情
    DetailRow(row) {
      this.$router.push({
        name: "circleDetail",
        params: { id: row.id, type: "edit" },
      });
    },
    //批量移出黑名单
    handleTransform() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中用户移出黑名单吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.loading = true;
            RemoveBlack({ id: this.selectArr })
              .then((res) => {
                this.$message.success(res.msg);
                this.reflashList();
              })
              .catch((err) => {
                this.loading = false;
              });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表移出黑名单
    TransformRow(row) {
      this.$msgbox
        .confirm("确定要将该用户移出黑名单吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.loading = true;
          RemoveBlack({ id: row.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.reflashList();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch((err) => {});
    },
    // 拉黑/移出记录
    RecodeRow(row) {
      this.circle_id = row.user_id;
      this.listType = "black";
      this.openRecodeList = true;
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          is_demand: -1, //内容类型
          check: -1, // 审核状态
          category: -1, // 内容分类
          keyword: "", //内容关键字
          user_keyword: "", //用户名关键字
          tab: e.name, //当前筛选tab
          page_size: this.pageData.per_page, //每页数据量
        };
      }
      this.thead =
        e.name !== "yesblack" && e.name !== "noblack"
          ? this.theadConfig.normal
          : e.name === "yesblack"
          ? this.theadConfig.yesblack
          : this.theadConfig.noblack;
      this.getCircleList(this.filterForm);
    },
    // 修改列表相关状态
    changeStatus(e) {
      const data = {};
      data.id = e.id;
      data.sort = e.top_sort;
      // if (data.sort % 1 !== 0 && data.sort <= 0) {
      //   this.$message.error("排序必须是正整数");
      //   return;
      // }
      // 更新排序状态
      this.loading = true;
      updateSort(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch(() => {
          this.reflashList();
        });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getCircleList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getCircleList({ ...this.filterForm, ...this.pageData });
    },
    // 刷新列表
    reflashList() {
      this.getCircleList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getCircleList(pageData);
    },
  },
  created() {
    const requestData = this.$route.query?.tab
      ? { ...this.filterForm, ...this.$route.query }
      : this.filterForm;
    this.filterForm.tab = this.$route.query?.tab
      ? this.$route.query.tab
      : this.filterForm.tab;
    this.getCircleList(requestData); //获取列表数据
  },
  components: {
    ListFilter,
    Pagination,
    HandleDialog,
    ChangeDataDialog,
    PopoverInputEdit,
    RecodeDialog,
    ReportRecodeDialog,
  },
};
</script>

<style lang="scss" scoped>
.content {
}
</style>
