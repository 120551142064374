<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm"
    :toggleCollapseEnable=" filterForm.tab !== 'yesblack' && filterForm.tab !== 'noblack'?true:false"
    :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item" v-if="filterForm.tab !== 'yesblack' && filterForm.tab !== 'noblack'">
        <el-form-item label="审核状态：" prop="check">
          <el-select v-model="check" placeholder="请选审核择状态">
            <el-option v-for="item in checkStatusOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容类型：" prop="is_demand">
          <el-select v-model="is_demand" placeholder="请选择内容类型">
            <el-option v-for="item in isDemandOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容分类：" prop="category">
          <el-select v-model="category" placeholder="请选择内容分类">
            <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item v-if="filterForm.tab !== 'yesblack' && filterForm.tab !== 'noblack'" label="内容标题："
          prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入内容标题"></el-input>
        </el-form-item>
        <el-form-item label="会员名称：" prop="user_keyword">
          <el-input v-model="form.user_keyword" placeholder="请输入会员名称"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item" v-if="form.tab !== 'user'">
        <DatePeriodPicker label="时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import { CategoryList, documentContentList } from "../../api/content-list.js";
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      checkStatusOptions: [
        { name: "待审核", id: 0 },
        { name: "已通过", id: 2 },
        { name: "未通过", id: 1 },
      ],
      isDemandOptions: [
        { name: "供应", id: 0 },
        { name: "需求", id: 1 },
      ],
      categoryList: [],
    };
  },
  methods: {
    // 获取分类列表
    getCategoryList() {
      CategoryList({ is_demand: -1 })
        .then((res) => {
          this.categoryList = res.data;
        })
        .catch((err) => {});
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page };
      documentContentList(postData).catch((err) => {});
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    this.getCategoryList();
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
    is_demand: {
      get() {
        return this.form.is_demand == -1 ? "" : this.form.is_demand;
      },
      set(val) {
        this.form.is_demand = val;
      },
    },
    check: {
      get() {
        return this.form.check == -1 ? "" : this.form.check;
      },
      set(val) {
        this.form.check = val;
      },
    },
    category: {
      get() {
        return this.form.category == -1 ? "" : this.form.category;
      },
      set(val) {
        this.form.category = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>