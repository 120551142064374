<template>
  <el-dialog :title="title" :visible.sync="openDialog"
             :width="!['setTop','setPay'].includes(type) ? '500px' : '537px'"
    class="dialog-vertical" @close="cancel('Form')">
    <el-form class="medium-form" size="medium" :model="Form" :rules="rules" ref="Form"
             :label-width="!['setTop','setPay'].includes(type) ? '65px':''">
      <el-form-item label="原因：" v-if="!['setTop','setPay'].includes(type)" style="margin-bottom: 20px" prop="reason">
        <el-input type="textarea" v-model="Form.reason"
          placeholder="请输入内容" :maxlength="20" show-word-limit style="min-height: 120px;width: 90%">
        </el-input>
        <p class="info"><i class="el-icon-info" />将通过订阅消息、系统消息发送通知</p>
      </el-form-item>
      <el-form-item v-if="type === 'setTop'" label="置顶时长：" label-width="100px" prop="hours">
        <el-input style="width: 200px" type="number" v-model.number="Form.hours" placeholder="请输入置顶时长">
        </el-input>
        <span style="margin-left: 10px">小时</span>
      </el-form-item>
      <el-form-item v-if="type === 'setPay'" label="付费方式：" label-width="100px" prop="price">
        <el-input style="width: 220px" type="number" placeholder="请输入数值" v-model.number="Form.price">
          <template slot="prepend">
            <el-select style="width: 85px" v-model="payType">
              <el-option v-for="item in payTypeOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </template>
        </el-input>
        <span v-if="!payType" style="margin-left: 10px">元</span>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="medium" @click="openDialog = false">取消</el-button>
      <el-button size="medium" type="primary" @click="submit('Form')" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  softDelete,
  NoCheck,
  JoinBlack,
  contentUpdateTop,
  UpdateVirtual,
  PayConfig,
} from "../../api/content-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String, Array],
    },
    userId: {
      type: [Number, String, Array],
    },
    type: {
      type: String,
      default: "",
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      Form: {
        reason: "", // 原因
        hours: "", //时长
        price: "", //价格
      },
      title: "", // 弹窗标题
      payType: 0,
      rules: {
        reason: [{ required: true, message: "请填写原因", trigger: "blur" }],
        hours: [
          { required: true, message: "请输入置顶时长", trigger: "blur" },
          {
            type: "number",
            min: 0.01,
            message: "置顶时长必须大于0",
            trigger: "blur",
          },
        ],
        price: [
          { required: true, message: "请输入费用", trigger: "blur" },
          {
            type: "number",
            min: 0.01,
            message: "费用必须大于0",
            trigger: "blur",
          },
        ],
      },
      // 付费方式
      payTypeOptions: [
        { name: "金额", id: 0 },
        { name: "积分", id: 1 },
      ],
      saveLoading: false,
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          if (this.type === "deleted") {
            const data = { id: this.id, delete_reason: this.Form.reason };
            softDelete(data)
              .then((res) => {
                this.handlePass(res.msg);
              })
              .catch((err) => {
                this.saveLoading = false;
              });
          }
          if (this.type === "nocheck") {
            const data = { id: this.id, check_reason: this.Form.reason };
            NoCheck(data)
              .then((res) => {
                this.handlePass(res.msg);
              })
              .catch((err) => {
                this.saveLoading = false;
              });
          }
          if (this.type === "block") {
            const data = {
              user_id: this.userId,
              id: this.id,
              why: this.Form.reason,
            };
            JoinBlack(data)
              .then((res) => {
                this.handlePass(res.msg);
              })
              .catch((err) => {
                this.saveLoading = false;
              });
          }
          if (this.type === "setTop") {
            const data = {
              id: this.id,
              top: this.Form.hours,
            };
            contentUpdateTop(data)
              .then((res) => {
                this.handlePass(res.msg);
              })
              .catch((err) => {
                this.saveLoading = false;
              });
          }
          if (this.type === "setPay") {
            const data = {
              id: this.id,
              pay: this.Form.price,
              pay_type: this.payType,
            };
            PayConfig(data)
              .then((res) => {
                this.handlePass(res.msg);
              })
              .catch((err) => {
                this.saveLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    //接口通过操作
    handlePass(msg) {
      this.updateList();
      this.$refs.Form.resetFields();
      this.$message.success(msg);
      this.saveLoading = false;
      this.openDialog = false;
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  watch: {
    type: {
      handler (val) {
        console.log(val, 'type')
        switch (val) {
          case "deleted":
            this.title = "删除原因";
            break;
          case "nocheck":
            this.title = "不通过审核";
            break;
          case "block":
            this.title = "拉黑原因";
            break;
          case "setTop":
            this.title = "设置置顶";
            break;
          case "setPay":
            this.title = "设为付费内容";
            break;
        }
      },
      immediate: true
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.el-input {
  ::v-deep.el-input-group__prepend {
    background-color: #fff;
  }
}
</style>
